import React, { useRef, useState } from "react";
import { rhythm } from "../utils/typography";

const initialState = {
  teamSize: 30,
  averageHoursPerWeek: 5,
  averageRatePerHour: 100,
  estimatedTimeReduction: 50,
  estimatedProductivityBoost: 20,
};

function calculate({
  teamSize,
  averageHoursPerWeek,
  averageRatePerHour,
  estimatedTimeReduction,
  estimatedProductivityBoost,
}) {
  const allMeetingsMonthlyCost =
    teamSize * (-averageHoursPerWeek * 4) * averageRatePerHour;

  const meetingReductionSavings = Math.floor(
    -allMeetingsMonthlyCost * (estimatedTimeReduction / 100)
  );
  const productivityBoostSavings =
    teamSize * averageRatePerHour * 40 * 4 * (estimatedProductivityBoost / 100);

  const savings = meetingReductionSavings + productivityBoostSavings;
  const totalSavings = savings;

  return {
    allMeetingsMonthlyCost,
    savings,
    meetingReductionSavings,
    productivityBoostSavings,
    totalSavings,
  };
}

export const PriceCalculator = () => {
  const [state, setState] = useState(initialState);

  const teamSizeInputRef = useRef(null);
  const averageHoursPerWeekInputRef = useRef(null);
  const averageRatePerHourInputRef = useRef(null);
  const estimatedTimeReductionInputRef = useRef(null);
  const estimatedProductivityBoostInputRef = useRef(null);

  function onChange() {
    function normalize(value, min = 0, max = Infinity) {
      if (!value && (value !== "0" || value !== 0)) return ""; // empty string case
      return Math.min(Math.max(value, min), max);
    }

    const newState = {
      teamSize: normalize(teamSizeInputRef.current.value),
      averageHoursPerWeek: normalize(
        averageHoursPerWeekInputRef.current.value,
        0,
        7 * 24
      ),
      averageRatePerHour: normalize(averageRatePerHourInputRef.current.value),
      estimatedTimeReduction: normalize(
        estimatedTimeReductionInputRef.current.value,
        0,
        100
      ),

      estimatedProductivityBoost: normalize(
        estimatedProductivityBoostInputRef.current.value,
        0,
        100
      ),
    };

    setState(newState);
  }

  const calculated = calculate(state);

  return (
    <div className="price-calculator-container">
      <div className="price-calculator">
        <section
          className="price-calculator__input"
          style={{
            paddingLeft: rhythm(2),
            paddingRight: rhythm(2),
            paddingTop: rhythm(1),
            paddingBottom: rhythm(1),
          }}
        >
          <div style={{ marginBottom: rhythm(1 / 4) }}>
            <label>
              My team size is{" "}
              <input
                type="number"
                style={{ width: 96 }}
                min={5}
                value={state.teamSize}
                onChange={onChange}
                ref={teamSizeInputRef}
              />{" "}
              people.
            </label>
          </div>
          <div style={{ marginBottom: rhythm(1 / 4) }}>
            <label>
              On average, each person attends{" "}
              <input
                type="number"
                style={{ width: 64 }}
                min={0}
                max={24 * 7}
                value={state.averageHoursPerWeek}
                onChange={onChange}
                ref={averageHoursPerWeekInputRef}
              />{" "}
              hours of internal meetings per week.
            </label>
          </div>
          <div style={{ marginBottom: rhythm(1 / 4) }}>
            <label>
              The average loaded cost of an employee in my company is ${" "}
              <input
                type="number"
                style={{ width: 64 }}
                min={0}
                value={state.averageRatePerHour}
                onChange={onChange}
                ref={averageRatePerHourInputRef}
              />{" "}
              per hour.
            </label>
          </div>
          <div style={{ marginBottom: rhythm(1 / 4) }}>
            <label>
              The estimated total reduction in meeting time with Remeet is %{" "}
              <input
                type="number"
                style={{ width: 64 }}
                min={0}
                max={100}
                value={state.estimatedTimeReduction}
                onChange={onChange}
                ref={estimatedTimeReductionInputRef}
              />{" "}
              percent.
            </label>
          </div>
          <div style={{ marginBottom: rhythm(1 / 4) }}>
            <label>
              The estimated productivity boost due to less distraction is %{" "}
              <input
                type="number"
                style={{ width: 64 }}
                min={0}
                max={100}
                value={state.estimatedProductivityBoost}
                onChange={onChange}
                ref={estimatedProductivityBoostInputRef}
              />{" "}
              percent.
            </label>
          </div>
        </section>
        <section
          className="price-calculator__output"
          style={{
            paddingLeft: rhythm(2),
            paddingRight: rhythm(2),
            paddingTop: rhythm(1),
            paddingBottom: rhythm(1),
          }}
        >
          <table>
            <tbody>
              <tr>
                <td>Your current spending on all internal meetings:</td>
                <td className="price-calculator__meetings-cost">
                  {formatCurrency(calculated.allMeetingsMonthlyCost)}/mo
                </td>
              </tr>
              <tr>
                <td>
                  Savings due to {state.estimatedTimeReduction}% reduction in
                  meeting time:
                </td>
                <td className="price-calculator__reduction-savings">
                  {formatCurrency(calculated.meetingReductionSavings)}/mo
                </td>
              </tr>
              <tr>
                <td>
                  Gain due to {state.estimatedProductivityBoost}% productivity
                  boost:
                </td>
                <td className="price-calculator__productivity-boost">
                  {formatCurrency(calculated.productivityBoostSavings)}/mo
                </td>
              </tr>
              <tr>
                <td>Estimated savings:</td>
                <td className="price-calculator__total-savings">
                  {formatCurrency(calculated.totalSavings)}/mo
                </td>
              </tr>
            </tbody>
          </table>
        </section>
      </div>
    </div>
  );
};

let formatter;
try {
  formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
} catch (e) {
  // not supported
  // super old browser
  // just render as string
}

function formatCurrency(value) {
  return (
    (value > 0 ? "+" : "") + (formatter ? formatter.format(value) : `$${value}`)
  );
}
