import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { rhythm } from "../utils/typography";
import "./pricing.scss";
import { PriceCalculator } from "../components/price-calculator";
import { PricingPlans } from "../components/pricing-plans";
import { CompareFeatures } from "../components/compare-features";

class Page extends React.Component {
  state = {
    productName: "Remeet",
  };

  componentDidMount() {}

  render() {
    return (
      <Layout withMaxWidth={false}>
        <SEO title={"Pricing"} />
        <section
          className="pricing"
          style={{
            paddingTop: rhythm(2.5),
            paddingBottom: rhythm(2.5),
            marginTop: rhythm(1),
          }}
        >
          <div className="pricing-container">
            <h1 className="pricing__title" style={{ marginTop: 0 }}>
              Pricing
            </h1>
          </div>
          <div className="pricing-plans-container">
            <PricingPlans />
          </div>
        </section>
        <section
          className="pricing-compare-features-wrapper"
          style={{ paddingTop: rhythm(2.5), paddingBottom: rhythm(2.5) }}
        >
          <div className="pricing-compare-features-container">
            <h1
              className="pricing-compare-features-container__title"
              style={{ marginTop: 0 }}
            >
              Compare our pricing plans
            </h1>
            <CompareFeatures />
          </div>
        </section>
        <section
          className="pricing-calculator-wrapper"
          style={{ paddingTop: rhythm(2.5), paddingBottom: rhythm(2.5) }}
        >
          <div className="pricing-calculator-container">
            <h1
              className="pricing-calculator-container__title"
              style={{ marginTop: 0 }}
            >
              <del>Pricing</del> Savings Calculator
            </h1>

            <p className="pricing-calculator-container__p">
              {this.state.productName} <strong>pays for itself</strong> by
              reducing the time spent in meetings. It also increases team
              productivity by eliminating the distraction of arbitrarily
              scheduled meetings.
            </p>
            <PriceCalculator />
          </div>
        </section>
        <section
          className="pricing-faqs-wrapper"
          style={{
            paddingTop: rhythm(2.5),
            paddingBottom: rhythm(2.5),
            borderTop: "1px solid #f5f5f1",
          }}
        >
          <div className="pricing-faqs-container">
            <h1
              className="pricing-faqs-container__title"
              style={{ marginTop: 0 }}
            >
              Pricing FAQs
            </h1>
            <h3> What are active users?</h3>
            <p>
              An active user is anyone that participates in 60 or more minutes
              of calls during a billing month.{" "}
            </p>
            <p>
              Unlike apps that charge for user seats, we charge only for users
              who actively use {this.state.productName}. And unlike other apps
              that consider users with minimal activity as active (like a single
              login or chat message), we'll only charge for users that spend 60
              minutes or more in meetings.{" "}
            </p>

            <p>
              Users that only access meeting notes, recordings & transcriptions
              are free.{" "}
            </p>

            <h3>How does monthly pricing work? </h3>

            <p>
              At the end of each month we look at your usage data and charge for
              the number of users that spent 60 or more minutes in meetings.{" "}
            </p>

            <p>Users that spend less than 60 minutes in meetings are free. </p>

            <p>
              Users that are added or deactivated during a given month will be
              prorated from the date of their first meeting, or the day of their
              deactivation.
            </p>

            <h3>How does annual pricing work? </h3>
            <p>
              Annual pricing offers a lower price compared to monthly usage in
              exchange for a 12 month commitment and advance payment. That
              commitment means there is no credit for months with lower active
              users.
            </p>

            <p>
              When you start your annual plan, you’ll pay for a number of active
              users for the 12 month period. If during any single month you have
              a higher number of active users than your billing plan, you’ll be
              charged a prorated amount for that number of users until the end
              of the billing year.{" "}
            </p>

            <h3>How do pay-as-you-go add ons like transcriptions work? </h3>

            <p>
              On a monthly plan, any add-ons are included on your monthly
              invoice.{" "}
            </p>

            <p>
              On an annual plan, you can prepay for a certain number of hours to
              avoid invoices for each month that add-ons are used. Any remaining
              prepaid hours will be transferred to the next billing year.{" "}
            </p>

            <h3>Do you offer a free trial? </h3>
            <p>
              Yes, we have a 30 day free trial with no credit card required.
              You’ll have all the functionality enabled on your account during
              the trial period. Near the end of the trial we’ll reach out to
              help you choose the right plan based on your trial usage.{" "}
            </p>

            <h3>Do you offer refunds? </h3>
            <p>
              We offer refunds for the remainder of the billing cycle within the
              first 30 days after payment.{" "}
            </p>
          </div>
        </section>
      </Layout>
    );
  }
}

export default Page;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
