import React from "react";
import "./pricing-plans.scss";
import { rhythm } from "../utils/typography";
import { RequestAccessBtn } from "./request-access-btn";

const pricingPlans = [
  {
    name: "Free",
    description: "Supercharge your weekly team meeting",
    priceYear: 0,
    priceMonth: 0,
    remeetsLimit: 1,
    userLimit: 15,
    recordingsRetention: "2 weeks",
    features:
      "Topics backlog, autonomous scheduling, Video conferencing, Note-taking",
  },
  {
    name: "Team",
    description: "For small teams that collaborate on a daily basis",
    priceYear: 10,
    priceMonth: 13,
    remeetsLimit: 5,
    userLimit: 25,
    recordingsRetention: "1 month",
    features:
      "Repeating topics, Optional participants, Google Calendar integration, Office 365 Calendar integration",
  },
  {
    name: "Business",
    description:
      "For organizations with multiple teams working closely together",
    priceYear: 25,
    priceMonth: 30,
    remeetsLimit: 10,
    userLimit: 50,
    recordingsRetention: "6 months",
    features:
      "Urgent-only, If-necessary, Quorum, Search, Watchlist, Small talks, Preparation, Slack integration",
  },
  {
    name: "Enterprise",
    description: "For large organizations with many interconnected teams",
    priceYear: undefined,
    remeetsLimit: undefined,
    userLimit: undefined,
    recordingsRetention: "1 year",
    features: "SLA, Reports/Analytics, Premium support",
  },
];

export const PricingPlanCard = ({
  name,
  description,
  priceYear,
  priceMonth,
  remeetsLimit,
  userLimit,
  recordingsRetention,
  features,
  isMonthly,
}) => {
  const price = isMonthly ? priceMonth : priceYear;
  return (
    <div className="pricing-plan-card">
      <div
        className="pricing-plan-card__name"
        style={{ marginTop: rhythm(0.5), marginBottom: rhythm(0.5) }}
      >
        {name}
      </div>
      <div
        className="pricing-plan-card__description"
        style={{ marginTop: rhythm(0.5), marginBottom: rhythm(0.5) }}
      >
        {description}
      </div>
      <div
        className="pricing-plan-card__price"
        style={{ marginTop: rhythm(1), marginBottom: rhythm(1) }}
      >
        {typeof price === "number" ? (
          <>
            <div>
              <sup>$</sup>
              {price}{" "}
              <span className="pricing-plan-card__price-currency">USD</span>
            </div>
            {price > 0 ? (
              <div className="pricing-plan-card__pp">
                per active user per month <br /> billed{" "}
                {isMonthly ? "monthly" : "annually"}
              </div>
            ) : (
              <div className="pricing-plan-card__pp">
                free concierge onboarding <br /> session included
              </div>
            )}
          </>
        ) : (
          <div className="pricing-plan-card__price-lets-chat">
            Let's chat{" "}
            <a className="clean" href="/getstarted/">
              <span role="img" aria-label="Chat bubble">
                💬
              </span>
            </a>
          </div>
        )}
      </div>
      <div
        className="pricing-plan-card__feature"
        style={{ marginTop: rhythm(0.5), marginBottom: rhythm(0.5) }}
      >
        <div className="pricing-plan-card__feature-key">weekly sync hours</div>
        <div className="pricing-plan-card__feature-value">
          {typeof remeetsLimit === "number"
            ? remeetsLimit === 1
              ? `One`
              : `Up to ${remeetsLimit}`
            : "Unlimited"}
        </div>
      </div>
      <div
        className="pricing-plan-card__feature"
        style={{ marginTop: rhythm(0.5), marginBottom: rhythm(0.5) }}
      >
        <div className="pricing-plan-card__feature-key">maximum users</div>
        <div className="pricing-plan-card__feature-value">
          {typeof userLimit === "number" ? `Up to ${userLimit}` : "Unlimited"}
        </div>
      </div>
      <div
        className="pricing-plan-card__feature"
        style={{ marginTop: rhythm(0.5), marginBottom: rhythm(0.5) }}
      >
        <div className="pricing-plan-card__feature-key">cloud recordings</div>
        <div className="pricing-plan-card__feature-value">
          {recordingsRetention}
        </div>
      </div>
      {/* <div
        className="pricing-plan-card__feature"
        style={{ marginTop: rhythm(0.5), marginBottom: rhythm(0.5) }}
      >
        <div className="pricing-plan-card__feature-key">features</div>
        <div className="pricing-plan-card__features__feature-value">
          {features}
        </div>
      </div> */}
      <RequestAccessBtn
        style={{ marginTop: rhythm(1), marginBottom: rhythm(1) }}
      >
        {priceMonth === 0 ? `Get started` : "30-day trial"}
      </RequestAccessBtn>
    </div>
  );
};

// TODO: animation
const TermSwitch = ({ isMonthly, setIsMonthly }) => (
  <div className="term-switch">
    {isMonthly ? (
      <>
        <button
          className="mdc-button mdc-button--unelevated"
          onClick={() => setIsMonthly(true)}
        >
          Monthly
        </button>
        <button
          className="mdc-button mdc-button--text"
          onClick={() => setIsMonthly(false)}
        >
          Annually
        </button>
      </>
    ) : (
      <>
        <button
          className="mdc-button mdc-button--text"
          onClick={() => setIsMonthly(true)}
        >
          Monthly
        </button>
        <button
          className="mdc-button mdc-button--unelevated"
          onClick={() => setIsMonthly(false)}
        >
          Annually
        </button>
      </>
    )}
  </div>
);

export const PricingPlans = () => {
  const [isMonthly, setIsMonthly] = React.useState(false);
  return (
    <>
      <div style={{ textAlign: "center", marginBottom: rhythm(1) }}>
        <TermSwitch isMonthly={isMonthly} setIsMonthly={setIsMonthly} />
      </div>
      <div className="pricing-plans">
        {pricingPlans.map((plan) => (
          <PricingPlanCard {...plan} key={plan.name} isMonthly={isMonthly} />
        ))}
      </div>
    </>
  );
};
