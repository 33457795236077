import React from "react";
import "./compare-features.scss";
import { Tooltip } from "@rmwc/tooltip";

const plans = ["Free", "Team", "Business", "Enterprise"];
const features = [
  {
    sectionName: "Topics backlog / scheduling",
    features: [
      {
        featureName: "Topics backlog",
        availability: [true, true, true, true],
        description: "Manage discussion topics for the entire team.",
      },
      {
        featureName: "Autonomous scheduling",
        availability: [true, true, true, true],
        description:
          "Our algorithm will find the most optimal schedule for the entire team based on the backlog of topics and individual availability.",
      },
      {
        featureName: "Search",
        description:
          "Full text search of topics, notes, and meeting transcriptions (add-on).",
        availability: [true, true, true, true],
      },
      {
        featureName: "Urgency",
        availability: [true, true, true, true],
        description: "Prioritize topics based on urgency. ",
      },
      {
        featureName: "Optional participants",
        availability: [false, true, true, true],
        description:
          "Optional participants on Remeet are truly optional. The algorithm will make a determination on whether to invite people based on their availability. If they weren't invited, the recording would be automatically added to their watchlist. ",
      },
      {
        featureName: "Watchlist",
        availability: [false, false, true, true],
        description:
          "Add topics to your watch list as you discover them on chat and watch them later at the most convenient time. Also, if you missed a topic, or you were optional — its recording will be added to your watchlist automatically.",
      },
      {
        featureName: "Quorum",
        availability: [false, false, true, true],
        description:
          "Ability to indicate how many people are required for a topic to happen (e.g. create a brainstorming session when 5 out of 10 people available).",
      },
      {
        featureName: "Repeating topics",
        availability: [false, false, true, true],
        description:
          "Topics that happen periodically without congesting your schedule.",
      },
      {
        featureName: "Wait for prep",
        availability: [false, false, false, true],
        description:
          "Forget about people coming unprepared by scheduling topics that require preparation. Those topics will be scheduled automatically after everyone's ready.",
      },
      {
        featureName: "Urgent-only",
        availability: [false, false, false, true],
        description: "Ability to accept remeet for only urgent topics.",
      },
      {
        featureName: "If-necessary",
        availability: [false, false, false, true],
        description:
          "Ability to accept remeet and indicate that you would prefer an algorithm to schedule topics during other blocks if that's possible.",
      },
      {
        featureName: "Pinned topics",
        availability: [false, false, false, true],
        description:
          "Ability to pin a topic to a specific remeet. E.g. schedule a weekly kick-off with your team first thing on Monday.",
      },
      {
        featureName: "Topic dependencies",
        availability: [false, false, false, true],
        description: "Schedule dependent topics in a sequence.",
      },
    ],
  },
  {
    sectionName: "Sync hours",
    features: [
      {
        featureName: ["HD video conferencing", "with 4k screen-sharing"],
        availability: [true, true, true, true],
        description:
          "Quality will automatically adjust based on the bandwidth and other network conditions.",
      },
      {
        featureName: "Simultaneous topic discussions",
        availability: [true, true, true, true],
        description: "Sub-groups discuss multiple topics simultaneously.",
      },
      {
        featureName: ["Instant transition", "from one topic to another"],
        availability: [true, true, true, true],
        description:
          "Your entire team will be automatically transitioned from one topic to another. Transition to the next one usually happens in less than a second.",
      },
      {
        featureName: ["Join any transparent topic"],
        availability: [true, true, true, true],
        description:
          "Boost communication transparency by allowing people who are free to join any other topic without being explicitly invited.",
      },
      {
        featureName: "Collaborative note-taking",
        availability: [true, true, true, true],
        description:
          "The collaborative document is the most essential part of every topic. You can use it to provide information ahead of time, capture notes, and assign action items during the meeting.",
      },
      {
        featureName: "Count-down timer",
        availability: [true, true, true, true],
        description:
          "Most conferencing apps offer a count-up timer, but to make your topics efficient, you really need a count-down timer. ",
      },
      {
        featureName: "Hard stop indicator",
        availability: [true, true, true, true],
        description:
          "You'll see when other people have a hard stop, and they'll see if you have to go as well. This is extremely helpful to ensure that topics end on time, and no one is late for their next topic. ",
      },
      {
        featureName: ["Speaking time indicator"],
        availability: [false, false, true, true],
        description:
          "Real-time stats of how long each person has been speaking in the current meeting.",
      },
      {
        featureName: "Recording transcription",
        availability: [false, false, "Add-on", "Add-on"],
        description: "Pay-as-you-go pricing.",
      },
      {
        featureName: "Meeting Summary",
        availability: [false, false, "Add-on", "Add-on"],
        description: "Automatically generated meeting summaries using recording transcriptions",
      },
    ],
  },
  {
    sectionName: "Team building",
    features: [
      {
        featureName: "Lobby / watercooler",
        availability: [true, true, true, true],
        description: "Virtual watercooler room for your team to socialize.",
      },
      {
        featureName: "Team picture",
        availability: [true, true, true, true],
        description:
          "Generate team pictures from topic recordings with the facial expression AI that finds the best shots.",
      },
      {
        featureName: "Small talks",
        availability: [false, false, false, true],
        description: "Chance meetings to boost morale and innovation.",
      },
    ],
  },
  {
    sectionName: "Integrations",
    features: [
      {
        featureName: "Google Calendar integration",
        availability: [true, true, true, true],
        description:
          "Google Workspace (G Suite) account is required. Personal @gmail.com accounts aren't supported.",
      },
      {
        featureName: "Office 365 Calendar integration",
        availability: [true, true, true, true],
      },
      {
        featureName: "Slack integration",
        availability: [false, true, true, true],
        description:
          "Authenticate, schedule topics, receive reminders, have recording and notes posted to Slack threads.",
      },
      {
        featureName: "Google chat integration",
        availability: [false, true, true, true],
        description:
          "Schedule topics, receive reminders, have recording and notes posted to Google chat threads.",
      },
      {
        featureName: "Mattermost integration",
        availability: [false, true, true, true],
        description:
          "Schedule topics, receive reminders, have recording and notes posted to Mattermost threads.",
      },
    ],
  },
  {
    sectionName: "Reporting / analytics",
    features: [
      {
        featureName: "Backlog report",
        availability: [false, true, true, true],
        description: "Address availability bottlenecks.",
      },
      {
        featureName: "Wait time report",
        availability: [false, false, true, true],
        description:
          "Reduce the average time it takes for a topic discussion to happen.",
      },
      {
        featureName: "Network report",
        availability: [false, false, false, true],
        description: "Make your team better connected.",
      },
      {
        featureName: "Transparency report",
        availability: [false, false, false, true],
        description: "Improve communication transparency.",
      },
      {
        featureName: "Urgency report",
        availability: [false, false, false, true],
        description: "Monitor the use of urgent and very urgent topics.",
      },
      {
        featureName: "One-on-ones report",
        availability: [false, false, false, true],
        description: "Ensure that everyone has at least one one-on-one.",
      },
      {
        featureName: "Custom export",
        availability: [false, false, true, true],
        description: "Export topics as a CSV file and create your own reports.",
      },
    ],
  },

  {
    sectionName: "Security, control and support",
    features: [
      {
        featureName: ["Single sign-on (SSO)"],
        availability: [true, true, true, true],
        description: "G Suite, Office 365, Slack",
      },
      {
        featureName: ["Transport level encryption of", "video and audio"],
        availability: [true, true, true, true],
      },
      {
        featureName: ["Private topics"],
        availability: [false, true, true, true],
      },
      {
        featureName: ["Sensitive topics"],
        availability: [false, true, true, true],
      },
      {
        featureName: ["End to end encrypted topics"],
        availability: [false, true, true, true],
        description: [
          `Video and audio streams in E2E Encrypted meetings are encrypted end-to-end and can never be accessed by us or our partners.`,
          `Each participant in a end-to-end encrypted peer-to-peer meeting negotiates a separate DTLS/SRTP connection to every other participant. All media is sent over these secure connections, and is encrypted only at the sender and decrypted only at the receiver`,
          `Network Traversal Service TURN cannot decrypt media: TURN only routes the packet between peers.`,
        ],
      },
      {
        featureName: ["Teams"],
        availability: [false, false, true, true],
        description: "Ability to create sub-teams within your workspace",
      },
      {
        featureName: ["Premium support"],
        availability: [false, false, true, true],
      },
      {
        featureName: ["%99.9 guaranteed uptime SLA"],
        availability: [false, false, false, true],
      },
    ],
  },
  {
    sectionName: "Limits",
    features: [
      {
        featureName: "Sync hours per week",
        availability: ["One", "Up to 5", "Up to 10", "Unlimited"],
        description:
          "This is a number of unique sync hours. E.g. 9 AM and 1 PM daily = 10 sync hours per week.",
      },
      {
        featureName: "Maximum workspace users",
        availability: ["Up to 15", "Up to 25", "Up to 50", "Unlimited"],
        description:
          "Maximum number of users that you can invite into your workspace on this plan.",
      },
      {
        featureName: "Automatic cloud recording",
        availability: ["2 weeks", "1 month", "6 months", "1 year"],
        description: "No additional storage costs.",
      },
    ],
  },
];

const CheckIcon = () => (
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.2998 21.56L6.60082 16L4.66016 17.88L12.2998 25.3334L28.6998 9.33337L26.7728 7.45337L12.2998 21.56Z"
      fill="#4DB6AC"
    />
  </svg>
);

const QuestionIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="#4DB6AC"
    width="18px"
    height="18px"
    style={{ verticalAlign: "text-bottom" }}
  >
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z" />
  </svg>
);

// TODO: this table is not accessible.
// Screen readers wouldn't understand it ☹️
export const CompareFeatures = () => {
  return features.map((section, idx) => (
    <div className="compare_features__section" key={idx}>
      <div className="compare_features__section-header">
        <div
          className="compare_features__section-name"
          dangerouslySetInnerHTML={{ __html: section.sectionName }}
        ></div>
        {plans.map((plan) => (
          <div className="compare_features__plan" key={plan}>
            {plan}
          </div>
        ))}
      </div>
      <div className="compare_features__section-body">
        {section.features.map((feature) => {
          const featureKey =
            typeof feature.featureName === "string"
              ? feature.featureName
              : feature.featureName[0];
          return (
            <React.Fragment key={featureKey}>
              <div className="compare_features__feature">
                <>
                  {typeof feature.featureName === "string"
                    ? feature.featureName
                    : feature.featureName.map((fLine, idx) => (
                        <React.Fragment key={fLine}>
                          {fLine}
                          {idx !== feature.featureName.length - 1 && <br />}
                        </React.Fragment>
                      ))}
                  {feature.description && (
                    <Tooltip
                      content={
                        <div className={"compare_features__feature-tooltip"}>
                          {feature.description}
                        </div>
                      }
                    >
                      <span
                        role="img"
                        aria-label="Learn more"
                        style={{ marginLeft: 4 }}
                      >
                        <QuestionIcon />
                      </span>
                    </Tooltip>
                  )}
                </>
              </div>
              {feature.availability.map((availability, aIdx) => {
                const formatAsText = () =>
                  typeof availability === "string"
                    ? availability
                    : availability === true
                    ? "Available"
                    : "Not available";

                const formatAsIcon = () =>
                  typeof availability === "string" ? (
                    availability
                  ) : availability === true ? (
                    <span role="img" aria-label="Available">
                      <CheckIcon />
                    </span>
                  ) : null;

                return (
                  <div
                    className="compare_features__availability"
                    key={aIdx}
                    title={formatAsText()}
                    aria-label={formatAsText()}
                  >
                    {formatAsIcon()}
                  </div>
                );
              })}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  ));
};
